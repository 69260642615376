import React, { useState, useEffect } from "react";

// import { BusinessesAndOrganizations } from "components/our-customers/config";

import Hero from "assets/technology.svg";
import Bluecheck from "assets/bluecheck.svg";
import Whitearrow from "assets/whitearrow.svg";
import { TechnologyData } from "./config";
import { Link } from "gatsby";
import Arrow from "assets/rightarrow.svg";
import ClientPortal from "./clientPortal";
import VendertPortal from "./venderPortal";
import MachineTranslation from "assets/technology/MachineTranslation.mp4";
import EnquiryForm from "components/EnquiryForm";
import Servicesicon from "components/homepage/translationServices/servicesicon";
import QuotePopUp from "components/popup/quotePopUp/index";

export default function Index() {
  const useExperiment = (experimentId) => {
    const [variant, setVariant] = useState();

    useEffect(() => {
      (async () => {
        // if (window.dataLayer) {
        //   await window.dataLayer.push({ event: "optimize.activate" });
        // }
        const intervalId = setInterval(() => {
          if (window.google_optimize !== undefined) {
            // Set the variant to the state.
            setVariant(window.google_optimize.get(experimentId));
            clearInterval(intervalId);
          }
        }, 100);
      })();
    }, []);
    return variant;
  };

  const variant = useExperiment("yWZT-4xlS9-M9AZuPD95Xg");
  const backgroundImageStyle = {
    backgroundImage: `url(${Hero})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="mainContainer text-white font-primary text-2xl">
        <section className="bg-gradient1  relative bg-desktop bg-cover   sm:px-0 pt-5 md:pt-16 pb-8 ">
          <div className="gradient px-2">
            {/* <div className="md-transparent md:bg-gradient-to-t from-[#00173A] to-transparent h-full "> */}
            <div className="max-w-7xl mx-auto flex flex-col">
              <div className="flex flex-col lg:flex-row gap-6 xl:gap-0 w-full px-3 sm:px-0  mx-auto justify-between items-start ">
                <div>
                  <div className="pb-5">
                    <ul className=" gap-8 lg:flex ">
                      <li className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.445 17.6564C11.3529 18.1088 10.1821 18.3409 9 18.3393C7.81788 18.3409 6.64712 18.1087 5.555 17.6564C4.46287 17.204 3.4709 16.5402 2.63611 15.7032C1.79912 14.8685 1.13536 13.8765 0.682965 12.7844C0.230575 11.6922 -0.00152412 10.5215 7.53144e-06 9.33936C-0.00149947 8.15724 0.230611 6.98648 0.683 5.89435C1.13539 4.80222 1.79914 3.81026 2.63611 2.97546C3.4709 2.13849 4.46287 1.47474 5.555 1.02236C6.64712 0.569966 7.81788 0.337856 9 0.339363C10.1821 0.337831 11.3529 0.56993 12.445 1.02232C13.5372 1.47471 14.5291 2.13847 15.3639 2.97546C16.2009 3.81026 16.8646 4.80222 17.317 5.89435C17.7694 6.98648 18.0015 8.15724 18 9.33936C18.0015 10.5215 17.7694 11.6922 17.317 12.7844C16.8646 13.8765 16.2009 14.8685 15.3639 15.7032C14.5291 16.5402 13.5372 17.204 12.445 17.6564ZM14.1364 7.27575C14.4879 6.92427 14.4879 6.35443 14.1364 6.00295C13.7849 5.65148 13.2151 5.65148 12.8636 6.00295L8.09999 10.7666L6.03639 8.70295C5.68492 8.35148 5.11507 8.35148 4.7636 8.70295C4.41213 9.05442 4.41213 9.62427 4.7636 9.97574L7.4636 12.6757C7.81507 13.0272 8.38492 13.0272 8.73639 12.6757L14.1364 7.27575Z"
                            fill="#4285F4"
                          />
                        </svg>
                        <p className="text-base text-[#000C27] font-medium pl-2 lg:text-left text-center">
                          Available Online 24/7
                        </p>
                      </li>

                      <li className="flex items-center my-4 lg:my-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.445 17.6564C11.3529 18.1088 10.1821 18.3409 9 18.3393C7.81788 18.3409 6.64712 18.1087 5.555 17.6564C4.46287 17.204 3.4709 16.5402 2.63611 15.7032C1.79912 14.8685 1.13536 13.8765 0.682965 12.7844C0.230575 11.6922 -0.00152412 10.5215 7.53144e-06 9.33936C-0.00149947 8.15724 0.230611 6.98648 0.683 5.89435C1.13539 4.80222 1.79914 3.81026 2.63611 2.97546C3.4709 2.13849 4.46287 1.47474 5.555 1.02236C6.64712 0.569966 7.81788 0.337856 9 0.339363C10.1821 0.337831 11.3529 0.56993 12.445 1.02232C13.5372 1.47471 14.5291 2.13847 15.3639 2.97546C16.2009 3.81026 16.8646 4.80222 17.317 5.89435C17.7694 6.98648 18.0015 8.15724 18 9.33936C18.0015 10.5215 17.7694 11.6922 17.317 12.7844C16.8646 13.8765 16.2009 14.8685 15.3639 15.7032C14.5291 16.5402 13.5372 17.204 12.445 17.6564ZM14.1364 7.27575C14.4879 6.92427 14.4879 6.35443 14.1364 6.00295C13.7849 5.65148 13.2151 5.65148 12.8636 6.00295L8.09999 10.7666L6.03639 8.70295C5.68492 8.35148 5.11507 8.35148 4.7636 8.70295C4.41213 9.05442 4.41213 9.62427 4.7636 9.97574L7.4636 12.6757C7.81507 13.0272 8.38492 13.0272 8.73639 12.6757L14.1364 7.27575Z"
                            fill="#4285F4"
                          />
                        </svg>
                        <p className="text-base text-[#000C27] font-medium pl-2 lg:text-left text-center">
                          1-Year Guarantee
                        </p>
                      </li>

                      <li className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.445 17.6564C11.3529 18.1088 10.1821 18.3409 9 18.3393C7.81788 18.3409 6.64712 18.1087 5.555 17.6564C4.46287 17.204 3.4709 16.5402 2.63611 15.7032C1.79912 14.8685 1.13536 13.8765 0.682965 12.7844C0.230575 11.6922 -0.00152412 10.5215 7.53144e-06 9.33936C-0.00149947 8.15724 0.230611 6.98648 0.683 5.89435C1.13539 4.80222 1.79914 3.81026 2.63611 2.97546C3.4709 2.13849 4.46287 1.47474 5.555 1.02236C6.64712 0.569966 7.81788 0.337856 9 0.339363C10.1821 0.337831 11.3529 0.56993 12.445 1.02232C13.5372 1.47471 14.5291 2.13847 15.3639 2.97546C16.2009 3.81026 16.8646 4.80222 17.317 5.89435C17.7694 6.98648 18.0015 8.15724 18 9.33936C18.0015 10.5215 17.7694 11.6922 17.317 12.7844C16.8646 13.8765 16.2009 14.8685 15.3639 15.7032C14.5291 16.5402 13.5372 17.204 12.445 17.6564ZM14.1364 7.27575C14.4879 6.92427 14.4879 6.35443 14.1364 6.00295C13.7849 5.65148 13.2151 5.65148 12.8636 6.00295L8.09999 10.7666L6.03639 8.70295C5.68492 8.35148 5.11507 8.35148 4.7636 8.70295C4.41213 9.05442 4.41213 9.62427 4.7636 9.97574L7.4636 12.6757C7.81507 13.0272 8.38492 13.0272 8.73639 12.6757L14.1364 7.27575Z"
                            fill="#4285F4"
                          />
                        </svg>
                        <p className="text-base text-[#000C27] font-medium pl-2 lg:text-left text-center">
                          95,000 Business Customers
                        </p>
                      </li>
                    </ul>
                  </div>
                  <h1 className="font-bold text-[32px] lg:text-46 font-opensans xs:font-secondary text-midBlue mb-2 w-full break-normal">
                    Translation and technology
                  </h1>

                  <div className="mt-12 inline-flex flex-col md:flex-row justify-start items-start md:items-center gap-7 font-opensans">
                    <Link
                      to="/quote"
                      className="uppercase rounded-full bg-orange hover:bg-newOrange-100 text-white cursor-pointer text-17 font-semibold py-4 px-8 w-[253px] text-center transition-colors duration-300"
                    >
                      {variant == "1" ? "START TRANSLATION" : "INSTANT QUOTE"}
                    </Link>
                  </div>
                </div>
                <div>
                  <EnquiryForm
                    title="Get in touch"
                    highlightWords="Get in touch"
                    highlightedColor="orange"
                    subtitle="We guarantee a reply in a few minutes."
                  />
                </div>
              </div>
              <div className="w-full pt-[80px] pb-[60px]">
                <Servicesicon />
              </div>{" "}
            </div>
            <QuotePopUp open={open} setOpen={setOpen} />
          </div>
        </section>
        <div className="my-24 max-w-7xl mx-auto px-4 pt-5 sm:px-6 md:py-12 bg-[#F5F9FF] text-[#00173A] rounded-lg">
          <div className="">
            <h1 className="text-4xl font-semibold leading-[60px] w-full text-center text-[#00173A] py-3 top-0 ">
              Our Technology
            </h1>
          </div>
          <div className="max-w-4xl mx-auto px-4 font-opensans leading-8 text-lg font-normal ">
            <p className="pt-8 text-center">
              Here at Tomedes, we hand-pick our translators for each project,
              based on their language skills and areas of expertise. Our
              translators utilize a wide range of programs and technologies to
              tailor their work to the client's needs – CAT (computer-assisted
              translation) tools, website and app localization platforms, design
              and DTP software, subtitling tools, and more.
            </p>

            <p className=" py-4 text-center">
              The combination of tried-and-true translation technologies and
              human expertise and experience allows us to provide comprehensive,
              in-house language solutions, oversee complex localization
              projects, translate from a wide range of media and file types, and
              use APIs to communicate with our clients' systems.
            </p>

            <p className=" pb-8 pt-4 text-center">
              By employing cutting-edge technologies, we are able to cut
              translation costs, shorten delivery times, and enhance translation
              quality.
            </p>
          </div>
        </div>
        <div className="bg-[#00173A] my-20 pt-[80px] pb-12 px-4 sm:px-6 lg:px-16">
          <div className="max-w-6xl mx-auto">
            <div className="max-w-4xl mx-auto">
              <p className="text-xl text-semibold text-center leading-7 text-[#2A7CF8]">
                Introducing Machinetranslation.com
              </p>
              <p className="text-[#FFFFFF] font-semibold text-center leading-[40px] md:leading-[50px] text-[28px] md:text-[40px] font-primary break-words">
                Try MachineTranslation.com Now
              </p>
              <p className="text-[#FFFFFF] text-lg font-opensans text-center leading-8 my-8">
                Our GPT-4-powered tool tailors machine translations for
                confident communication by individuals and businesses.
              </p>
              <div className="flex items-center justify-center">
                <Link to="/contactus.php">
                  <button className="text-[#2A7CF8] text-lg font-opensans leading-6 font-semibold flex">
                    Contact Us
                    <img
                      src={Arrow}
                      alt=""
                      className="mt-[2px]"
                      style={{
                        filter:
                          "brightness(0) saturate(100%) invert(65%) sepia(98%) saturate(6300%) hue-rotate(201deg) brightness(91%) contrast(105%)",
                      }}
                    />
                  </button>
                </Link>
              </div>
            </div>

            <video
              className="h-full w-full rounded-lg mt-12"
              controls
              autoPlay
              muted
            >
              <source
                src={MachineTranslation}
                type="video/mp4"
                loading="lazy"
              />
            </video>
          </div>
        </div>
        <ClientPortal />
        <VendertPortal />
        <div className="my-24 max-w-6xl mx-auto px-4 pt-5 sm:px-6 md:py-12   ">
          <p className="text-[#00173A] pt-16 pb-[80px] font-primary leading-[40px] md:leading-[50px] text-3xl md:text-[40px] font-semibold text-center">
            Our Translation Toolkit
          </p>
          <div className="grid lg:grid-cols-2 gap-8 lg:gap-16 ">
            {TechnologyData.map((item, i) => (
              <div>
                <div className=" px-4  ">
                  <div className="">
                    <img src={item.imgUrl} alt="" className="w-44 " />
                  </div>
                  <div className="text py-8">
                    <h1 className="text-[#00183C] text-2xl font-primary leading-9 font-semibold">
                      {item.title}
                    </h1>
                    <p className="text-[#00173A] text-lg font-normal font-opensans leading-8">
                      {item.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* <div className="mainCont flex flex-col bg-[#00173A] py-16 ">
          <div className=" relative   md:h-8 mb-16 sm:w-6/12 max-w-sm flex m-auto justify-center ">
            <h1 className="text-4xl font-medium w-full text-center  py-3 top-0 inset-x-0">
              Additional Tools
            </h1>
          </div>

          <div className="flex items-center justify-center w-full lg:w-full xl:w-full ">
            <div className="grid md:grid-cols-2 lg:grid-cols-6 gap-2 ">
              {BusinessesAndOrganizations
                ? BusinessesAndOrganizations?.slice(0, 6).map(
                    ({ url }, index) => (
                      <div
                        className="bg-white w-[160px] h-[160px] flex items-center justify-center rounded-lg"
                        key={index}
                      >
                        <img src={url} alt="img" className="w-28 block m-4" />
                      </div>
                    )
                  )
                : null}
            </div>
          </div>
        </div> */}

        <div className="px-4 ">
          <div className="grid lg:grid-cols-2 gap-12 max-w-7xl mx-auto  px-4 sm:px-6 py-28 ">
            <div className="flex flex-col items-center justify-center p-12 text-center rounded-lg bg-[#2A7CF8] ">
              <h3 className="pb-10 text-4xl font-primary leading-[60px] font-semibold text-center text-[#ffffff] ">
                Ready to get started?
              </h3>
              <Link
                to="/quote"
                className="fr-btn1 bg-white text-[#00173A] py-3 px-4  rounded-lg text-sm sm:text-base whitespace-nowrap mt-6 "
              >
                Translate Now
              </Link>
            </div>
            <div className="flex flex-col items-center justify-center p-12 text-[#00173A] bg-[#F5F9FF] lg:px-16">
              <h3 className="pb-10 text-4xl font-primary leading-[60px] font-semibold text-center ">
                Want to tell us more about your needs?
              </h3>
              <Link
                to="/contactus.php"
                className="fr-btn2 text-[#2A7CF8] flex gap-1 text-lg font-opensans mt-6"
              >
                Contact Us
                <img
                  src={Arrow}
                  alt=""
                  className="mt-[3px]"
                  style={{
                    filter:
                      "brightness(0) saturate(100%) invert(65%) sepia(98%) saturate(6300%) hue-rotate(201deg) brightness(91%) contrast(105%)",
                  }}
                />
              </Link>
            </div>
          </div>
          <div className="borderLine border-border border-b bg-basecol "></div>
        </div>
      </div>
    </>
  );
}
